.s-verify {
    &-message {
        @apply text-sm text-gray-500 text-center;
    }

    &-label {
        @apply block mb-4 text-center text-sm;
    }

    &-codes {
        @apply flex justify-between mb-5 -mx-1;
    }

    &-codes.has-error {
        @apply mb-0;
    }
    &-current{
       @apply text-[14px] font-medium mb-7 mt-2 text-center dark:text-white
    }
    &-input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        &[type="number"] {
            -moz-appearance: textfield;
        }
        @apply mx-1 transition-colors border border-gray-200 focus:border-primary text-lg rounded w-full h-10 px-2 text-center font-bold;
    }

    &-footer {
        @apply text-center;
    }

    &-submit {
        @apply w-full mb-5;
    }

    &-header-icon {
        @apply rtl:ml-2 ltr:mr-2 sm:rtl:ml-0 sm:ltr:mr-0 sm:mx-auto inline-flex items-center justify-center w-12 h-12 sm:h-20 sm:w-20 rounded-full sm:mb-4 border border-solid border-gray-200;
    }

    &-resend-message {
        @apply text-gray-400 text-sm;
    }

    &-error-message {
        @apply s-has-error text-xs block text-center h-7 pt-3 mb-5;
    }

    &-timer {
        @apply text-gray-700 mx-1;
    }

    &-resend {
        @apply text-sm text-primary text-center w-full;
    }
}
[dir="ltr"] .s-verify-back {
    transform: scale(-1);
}
