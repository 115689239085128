.s-show{
  display: block;
}
.s-hidden {
  display: none;
}
.s-ltr{
  direction: ltr;
}
.s-has-error{
  @apply border-red-500 text-red-500 #{!important}
}

body [type=text]:focus,
body [type=email]:focus,
body [type=url]:focus,
body [type=password]:focus,
body [type=number]:focus,
body [type=date]:focus,
body [type=tel]:focus,
body [type=time]:focus,
body [type=week]:focus,
body [multiple]:focus,
body textarea:focus,
body select:focus {
  @apply focus:ring-0 outline-none;
}

body [type=text], body [type=email], body [type=number], body [type=tel], body [multiple], body textarea, body select {
  @apply dark:bg-black/5 dark:border-gray-700 dark:text-white
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  /* 1 */
  font-feature-settings: inherit;
  /* 1 */
  font-variation-settings: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  font-weight: inherit;
  /* 1 */
  line-height: inherit;
  /* 1 */
  letter-spacing: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}
