.s-login-modal {
    &-header {
        @apply flex-col;
        &-inner {
            @apply mb-5 block text-center p-0;
        }
        &-content {
            @apply flex flex-col text-center;
        }
        &-icon {
            @apply ml-0 mr-0 mx-auto inline-flex items-center justify-center w-20 h-20 rounded-full mb-4 border border-solid border-gray-200 dark:border-gray-700;
            svg {
                @apply w-5 h-5 fill-gray-400 flex-shrink-0;
            }
        }
    }
    &-title {
        @apply mb-2 text-lg leading-6 font-bold text-gray-900 dark:text-white;
    }
    &-unactive {
        @apply opacity-0 translate-x-3;
    }
    &-active {
        @apply opacity-100 translate-x-0;
    }
    &-tab {
        @apply transition-all duration-500 ease-in-out;
    }
    &-wrapper {
        @apply transition-all duration-300 ease-elastic;
    }
    &-link {
        @apply text-sm text-primary block text-center mt-6 w-full;
    }
    &-label {
        @apply block text-sm font-medium text-gray-700 mb-2 dark:text-white;
    }
    &-input {
        @apply transition-colors border border-gray-200 focus:border-primary text-sm rounded w-full h-10 px-3;
    }
    &-error-message {
        @apply text-xs block h-7 pt-1 text-red-500;
    }
    &-filepond-placeholder {
        @apply flex flex-col justify-center items-center;
    }
    &-filepond-placeholder-icon {
        @apply mb-1;
        svg {
            fill: #7c8082;
            @apply w-6 h-6;
        }
    }
    .filepond--drop-label {
        min-height: 120px !important;
        @apply bg-white cursor-pointer border-dashed rounded border border-gray-200;
        label {
            @apply text-[0.8rem] py-[20px] px-[6px] cursor-pointer;
        }
    }
    &-verify-back {
        @apply flex left-[0] rtl:left-[unset] rtl:right-[0] items-center justify-center h-12 w-12 rounded-full text-lg absolute top-[0] text-gray-600 dark:bg-white/5 dark:fill-gray-500 bg-gray-50 hover:bg-gray-100 transition;
        svg {
            width: 18px;
            height: 18px;
        }
    }
}
[dir="ltr"] .s-login-modal-verify-back {
    transform: scale(-1);
}
.is-mobile {
    .s-login-modal {
        &-header {
            @apply flex sm:flex-col;
            &-inner {
                @apply mb-5 flex sm:block items-center text-center pr-8 rtl:pl-8 rtl:pr-0 md:p-0;
            }
            &-content {
                @apply flex flex-col text-left rtl:text-right md:text-center md:rtl:text-center;
            }
            &-icon {
                @apply rtl:ml-2 ltr:mr-2 sm:ltr:mr-0 sm:mx-auto inline-flex items-center justify-center w-12 h-12 sm:h-20 sm:w-20 rounded-full mb-0 border border-solid border-gray-200;
                svg {
                    @apply w-5 h-5 fill-gray-400 flex-shrink-0;
                }
            }
            &-title {
                @apply sm:mb-2 text-lg leading-6 font-bold text-gray-900;
            }
        }
    }
}
