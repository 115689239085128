@import url("https://cdn.salla.network/fonts/pingarlt.css?v=1.0");
@import "assets/styles/global";
@import "assets/styles/tel-input";
@import "assets/styles/btn";
@import "assets/styles/login";
@import "assets/styles/verify";
.App {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.3s;
}
body {
    font-family: "PingARLT", serif;
    font-weight: normal;
}
html,
body,
#root {
    height: 100%;
}
